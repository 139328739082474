<template>
  <collapse-card
    v-if="submissionRoutingSectionState !== INVISIBLE"
    :model-value="true"
  >
    <template #title>
      <span class="mr-2 text-sm font-medium">
        Submission Routing
      </span>
    </template>
    <template #cardContent>
      <div
        v-if="isLoading"
        class="h-12 w-full mt-4 text-center"
      >
        <base-svg
          class="h-4 w-4 mr-1 text-primary-red inline-block"
          src="icons/circleSpinner.svg"
          tag="span"
        />
        Loading Routing Options ...
      </div>

      <div
        v-else
        class="py-2 px-4"
      >
        <div class="flex justify-evenly mb-10">
          <base-checkbox
            v-if="placeSampleHoldFieldState() !== INVISIBLE"
            v-model="submissionForm.placeSampleHold"
            :disabled="placeSampleHoldFieldState() === VIEW_ONLY"
            value="Y"
            unchecked-value="N"
            container-class="flex items-center mr-3"
          >
            <template #label>
              <label class="ml-2 text-black text-sm">
                Place Sample Hold
              </label>
            </template>
          </base-checkbox>
          <base-checkbox
            v-if="placeSafetyHoldFieldState() !== INVISIBLE"
            v-model="submissionForm.placeSafetyHold"
            :disabled="placeSafetyHoldFieldState() === VIEW_ONLY"
            value="Y"
            unchecked-value="N"
            container-class="flex items-center mr-3"
          >
            <template #label>
              <label class="ml-2 text-black text-sm">
                Place Safety Hold
              </label>
            </template>
          </base-checkbox>
          <base-checkbox
            v-if="safetyNeededFieldState() !== INVISIBLE"
            v-model="submissionForm.isSafetyNeeded"
            :disabled="safetyNeededFieldState() === VIEW_ONLY"
            value="Y"
            unchecked-value="N"
            container-class="flex items-center mr-3"
          >
            <template #label>
              <label class="ml-2 text-black text-sm">
                Safety Needed
              </label>
            </template>
          </base-checkbox>
          <base-checkbox
            v-if="rushSubmissionFieldState() !== INVISIBLE"
            v-model="submissionForm.rushSubmission"
            value="Y"
            unchecked-value="N"
            container-class="flex items-center mr-3"
          >
            <template #label>
              <label class="ml-2 text-black text-sm">
                Rush Submission
              </label>
            </template>
          </base-checkbox>
          <base-checkbox
            v-if="escalateToSupervisorFieldState() !== INVISIBLE"
            v-model="submissionForm.escalateToSupervisor"
            :disabled="escalateToSupervisorFieldState() === VIEW_ONLY"
            value="Y"
            unchecked-value="N"
            container-class="flex items-center mr-3"
          >
            <template #label>
              <label class="ml-2 text-black text-sm">
                Escalate To Supervisor
              </label>
            </template>
          </base-checkbox>
          <base-checkbox
            v-if="safetyCompletedFieldState() !== INVISIBLE"
            v-model="submissionForm.isSafetyCompleted"
            :disabled="safetyCompletedFieldState() === VIEW_ONLY"
            value="Y"
            unchecked-value="N"
            container-class="flex items-center mr-3"
          >
            <template #label>
              <label class="ml-2 text-black text-sm">
                Safety Completed
              </label>
            </template>
          </base-checkbox>
          <base-checkbox
            v-if="sendToFinalFieldState() !== INVISIBLE"
            v-model="submissionForm.sendToFinal"
            :disabled="sendToFinalFieldState() === VIEW_ONLY"
            value="Y"
            unchecked-value="N"
            container-class="flex items-center mr-3"
          >
            <template #label>
              <label class="ml-2 text-black text-sm">
                Send to Safety/Final
              </label>
            </template>
          </base-checkbox>
          <base-checkbox
            v-if="skipToFinalFieldState() !== INVISIBLE"
            v-model="submissionForm.skipFinal"
            :disabled="skipToFinalFieldState() === VIEW_ONLY"
            value="Y"
            unchecked-value="N"
            container-class="flex items-center mr-3"
          >
            <template #label>
              <label class="ml-2 text-black text-sm">
                Skip Final
              </label>
            </template>
          </base-checkbox>
        </div>
        <div class="grid grid-cols-2 gap-x-10 gap-y-4">
          <base-input
            v-if="previousFieldsState !== INVISIBLE"
            v-model="submissionForm.previousStageName"
            type="text"
            container-class="flex items-center"
            label-class="label-w-46"
            label="Previous Stage"
            placeholder="--NA--"
            disabled
          />
          <base-input
            v-if="previousFieldsState !== INVISIBLE"
            v-model="submissionForm.previousStepName"
            type="text"
            container-class="h-7 flex items-center"
            label-class="label-w-46"
            label="Previous Step"
            placeholder="--NA--"
            disabled
          />
          <base-select
            v-if="currentStageFieldState !== INVISIBLE"
            v-model="submissionForm.currentStage"
            class="flex items-center text-sm text-left"
            placeholder="-- SELECT --"
            label="Current Stage"
            label-class="label-w-46"
            :disabled="currentStageFieldState === VIEW_ONLY"
            :error="submissionFormValidations.currentStage"
            :options="currentStageFieldValues()"
            item-text-property="stageName"
            item-value-property="stageKey"
            show-default-option
          />
          <base-select
            v-if="nextStageFieldState !== INVISIBLE"
            v-model="submissionForm.nextStage"
            class="flex items-center text-sm text-left"
            placeholder="-- SELECT --"
            label="Next Stage"
            label-class="label-w-46"
            :disabled="nextStageFieldState === VIEW_ONLY"
            :options="getDistinctArray(__posssibleSubmissionStages)"
            item-text-property="stageName"
            item-value-property="stageKey"
            show-default-option
          />
          <base-select
            v-if="currentStepFieldState !== INVISIBLE"
            v-model="submissionForm.currentStep"
            class="flex items-center text-sm text-left"
            placeholder="-- SELECT --"
            label="Current Step"
            label-class="label-w-46"
            disabled
            :options="getDistinctArray(__submissionSteps)"
            item-text-property="stepName"
            item-value-property="stepKey"
            show-default-option
          />
          <base-select
            v-if="nextStepFieldState !== INVISIBLE"
            v-model="submissionForm.nextStep"
            class="flex items-center text-sm text-left"
            placeholder="-- SELECT --"
            label="Next Step"
            label-class="label-w-46"
            disabled
            :options="getDistinctArray(__submissionSteps)"
            item-text-property="stepName"
            item-value-property="stepKey"
            show-default-option
          />
          <base-select
            v-if="legalNextStageFieldState !== INVISIBLE"
            v-model="submissionForm.legalNextStage"
            class="flex items-center text-sm text-left"
            placeholder="-- SELECT --"
            label="Legal Next Stage"
            label-class="label-w-46"
            :disabled="legalNextStageFieldState === VIEW_ONLY"
            :error-text="errorLegalNextStage"
            :error="submissionFormValidations.legalNextStage"
            item-text-property="stageName"
            item-value-property="stageKey"
            :options="getDistinctArray(filterNextStagesForLegalAndCreative(__posssibleSubmissionStages))"
            show-default-option
          />
          <base-select
            v-if="creativeNextStageFieldState !== INVISIBLE"
            v-model="submissionForm.creativeNextStage"
            class="flex items-center text-sm text-left"
            placeholder="-- SELECT --"
            label="Creative Next Stage"
            label-class="label-w-46"
            :disabled="creativeNextStageFieldState === VIEW_ONLY"
            :error-text="errorCreativeNextStage"
            :error="submissionFormValidations.creativeNextStage"
            item-text-property="stageName"
            item-value-property="stageKey"
            :options="getDistinctArray(filterNextStagesForLegalAndCreative(__posssibleSubmissionStages))"
            show-default-option
          />
          <base-select
            v-if="legalStatusFieldState !== INVISIBLE"
            v-model="submissionForm.legalStatus"
            class="flex items-center text-sm text-left"
            placeholder="-- SELECT --"
            label="Legal Status"
            label-class="label-w-46"
            :disabled="legalStatusFieldState === VIEW_ONLY"
            :error="submissionFormValidations.legalStatus"
            item-text-property="statusName"
            item-value-property="statusKey"
            :options="submissionStatusListLegal.length > 0 ? getDistinctArray(submissionStatusListLegal) : getDistinctArray(__submissionStatusList)"
            show-default-option
          />
          <base-select
            v-if="creativeStatusFieldState !== INVISIBLE"
            v-model="submissionForm.creativeStatus"
            class="flex items-center text-sm text-left"
            placeholder="-- SELECT --"
            label="Creative Status"
            label-class="label-w-46"
            :disabled="creativeStatusFieldState === VIEW_ONLY"
            :error="submissionFormValidations.creativeStatus"
            text-container-class="w-full h-7 inline-block p-1 text-left"
            item-text-property="statusName"
            item-value-property="statusKey"
            :options="submissionStatusListCreative.length > 0 ? getDistinctArray(submissionStatusListCreative) : getDistinctArray(__submissionStatusList)"
            show-default-option
          />
          <base-input
            v-if="(submissionStatusFieldState !== INVISIBLE && submissionForm.submissionStatus !== 'AdminEditEnabled') && previousFieldsState !== INVISIBLE"
            v-model="submissionForm.submissionStatus"
            type="text"
            container-class="flex items-center"
            label-class="label-w-46"
            label="Submission Status"
            placeholder="--NA--"
            :disabled="submissionStatusFieldState === VIEW_ONLY"
          />
          <base-input
            v-if="submissionStatusFieldState !== INVISIBLE && submissionForm.submissionStatus === 'AdminEditEnabled'"
            type="text"
            value="Completed"
            container-class="flex items-center"
            label-class="label-w-46"
            label="Submission Status"
            placeholder="--NA--"
            :disabled="submissionStatusFieldState === VIEW_ONLY"
          />
          <base-select
            v-if="currentActionFieldState !== INVISIBLE"
            v-model="submissionForm.currentAction"
            class="flex items-center text-sm text-left"
            placeholder="-- SELECT --"
            :label="submissionForm.currentStepName === BAC_SUPERVISOR_STEP ? 'Submission Status' : 'Action'"
            label-class="label-w-46"
            :disabled="currentActionFieldState === VIEW_ONLY"
            :error="submissionFormValidations.currentAction"
            item-text-property="statusName"
            item-value-property="statusKey"
            :error-text="errorTextActionFieldBAC"
            :options="submissionStatusListAction.length > 0 ? getDistinctArray(submissionStatusListAction) : getDistinctArray(__submissionStatusList)"
            show-default-option
          />
          <base-select
            v-if="submissionReviewerField !== INVISIBLE"
            v-model="submissionForm.routebackStep"
            class="flex items-center text-sm text-left"
            placeholder="-- SELECT --"
            label="Select Reviewer"
            :error="submissionFormValidations.routebackStep"
            label-class="label-w-46"
            item-text-property="stepName"
            item-value-property="stepName"
            :options="getDistinctArray(submissionReviewers)"
          />
          <base-select
            v-if="submissionUserFieldState !== INVISIBLE"
            v-model="submissionForm.assignToUser"
            class="flex items-center text-sm text-left"
            placeholder="-- SELECT --"
            label="Select User"
            label-class="label-w-46"
            item-text-property="username"
            item-value-property="username"
            :options="getDistinctArray(fetchedUserListOnBaseOfRole)"
          />
        </div>
      </div>
    </template>
  </collapse-card>
  <base-modal
    v-model="showSafetyNeededModal"
    modal-title="Confirm Saftey Needed"
    :disable-click-on-overlay-close="true"
    :hide-close-button="true"
  >
    <template #modalBody>
      <div class="px-4 py-3">
        <div class="font-sm text-center mb-2">
          Are you sure this item does not require Safety Review? If you proceed, Safety Hold and Safety Completed flags will be cleared from this item. Do you want to continue?
        </div>
        <div class="text-center mt-2">
          <base-button
            class="mr-3"
            variant="btn-primary"
            text="Yes"
            @click="confirmedSafetyNeededCanBeNo()"
          />
          <base-button
            variant="btn-link"
            text="No"
            @click="resetSafetyNeededToYes()"
          />
        </div>
      </div>
    </template>
  </base-modal>
  <base-modal
    v-model="showIsSafetyCompletedConfirmationModal"
    modal-title="Confirm Message"
    @hidden="hideIsSafetyCompletedConfirmationModal()"
  >
    <template #modalBody>
      <div class="px-4 py-3">
        <div class="font-sm text-center mb-2">
          This submission has Safety Completed flag selected. Selecting Safety Hold will clear the Safety Completed flag. Do you want to continue?
        </div>
        <div class="text-center mt-2">
          <base-button
            class="mr-3"
            variant="btn-primary"
            text="Yes"
            @click="confirmIsSafetyCompletedConfirmationModal()"
          />
          <base-button
            variant="btn-link"
            text="No"
            @click="closeIsSafetyCompletedConfirmationModal()"
          />
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { computed, defineAsyncComponent, watch, ref, onMounted } from 'vue';
import useSubmissionForm from './submissionForm.js';
import {
    PREMIUM_GWPS_PRODUCTS_METARIAL, ANNUALS_STAGE, ASSIGN_TO_STATUS, BAC_FINAL_STEP, BAC_REVIEW_STEP,
    BAC_SUPERVISOR_STEP, CONCEPT_STAGE, CONTRACTUAL_STAGE, CREATIVE_REVIEW_STEP, EDITABLE, GENERIC_SUBMISSION_TYPE,
    INTERACTIVE_SUBMISSION_TYPE, INVISIBLE, LEGAL_REVIEW_STEP, LICENSEE_STEP, OPA_SUBMISSION_TYPE,
    PRESS_RELEASE_SUBMISSION_TYPE, PRINT_ON_DEMAND_SUBMISSION_TYPE, PRODUCTION_STAGE, REJECT_STATUS,
    REQUIRED, RESUBMIT_WITH_CHANGES_STATUS, ROUTE_BACK_STATUS, SAFETY_STEP, STANDARD_SUBMISSION_TYPE,
    VIEW_ONLY, PRE_PRODUCTION_STAGE, REVISED_STAGE, APPROVE_STATUS, APPROVE_WITH_CHANGES_STATUS, FINAL_STAGE, 
    SOCIAL_REVIEW_STEP, GAMES_REVIEW_STEP, DIGITAL_REVIEW_STEP, SOCIAL_MEDIA_POST_METARIAL, SOCIAL_DIGITAL_VIDEO_REVIEW, DPA_MATERIALS, DPA_GOODS_MATERIAL, DPA_GAMES_MATERIAL, DPA_UX_MATERIAL, DPA_AR_MATERIAL, DPA_STICKER_MATERIAL
} from '@/constants/submissions.js';
import { REQUIRED_FIELD } from '@/constants/validationMessages.js';
import useUserAuth from '@/hooks/userAuth.js';
import {
    BAC_ADMIN_ROLE, BAC_REVIEWER_ROLE, BAC_SUPERVISOR_ROLE, CREATIVE_ROLE, LEGAL_ROLE, LICENSEE_ROLE, SAFETY_ROLE,
    SOCIAL_REVIEWER_ROLE, GAMES_REVIEWER_ROLE, DIGITAL_REVIEWER_ROLE } from '@/constants/ba-roles.js';
import { SUBMISSION_FORM_SUBMISSION_ROUTING_PERMISSION } from '@/constants/permissions.js';
import { getDistinctArray } from '@/helpers/util';
import { useStore } from 'vuex';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { ERROR, WARNING } from '@/constants/alerts';
import * as BA_ROLES from '@/constants/ba-roles.js';

export default {
    name: 'SubmissionRouting',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue')),
        BaseSelect: defineAsyncComponent(() => import('@/components/generic-components/BaseSelect.vue')),
        BaseCheckbox: defineAsyncComponent(() => import('@/components/generic-components/BaseCheckbox.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue')),
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue'))
    },

    setup () {
        const store = useStore();
        const { notificationsStack } = useToastNotifications();
        const { submissionForm, notifiersEnabled, submissionFormValidations, submissionAssignToUserList } = useSubmissionForm();
        const { userRoles, userPermissions } = useUserAuth();
        const proxyUser = computed(() => store.getters['auth/getUserProxyInfo']);

        const previousFieldsState = computed(() => {
            return (userRoles.value.includes(LICENSEE_ROLE) && userRoles.value.length === 1) || LICENSEE_STEP === submissionForm.currentStepName ? INVISIBLE : EDITABLE;
        });

        const __submissionStages = computed(() => store.getters['baSubmissionRouting/getStageList'] || []);
        const __posssibleSubmissionStages = computed(() => {
            const possibleStages = store.getters['baSubmissionRouting/getPossibleStageList'] || [];
            const filteredStages = possibleStages.filter(s => [CONTRACTUAL_STAGE, ANNUALS_STAGE].includes(submissionForm.currentStageName) ? s.stageName : s.stageName !== ANNUALS_STAGE);
            return filteredStages;
        });
        const __submissionSteps = computed(() => store.getters['baSubmissionRouting/getStepList'] || []);
        const __submissionStatusList = computed(() => store.getters['baSubmissionRouting/getStatusList'] || []);
        const errorTextActionFieldBAC = ref('');
        const fetchedUserListOnBaseOfRole = computed(() => {
            const usersList = store.getters['users/getUsersList'];
            return usersList?.data || [];
        });
        const fetchUserListBasedOnReviewerSelected = async (val) => {
            let roleName = '';
            if (val === CREATIVE_REVIEW_STEP) {
                roleName = CREATIVE_ROLE;
            } else if (val === LEGAL_REVIEW_STEP) {
                roleName = LEGAL_ROLE;
            } else if (val === BAC_SUPERVISOR_STEP) {
                roleName = BAC_SUPERVISOR_ROLE;
            } else if (val === BAC_REVIEW_STEP) {
                roleName = BAC_REVIEWER_ROLE;
            } else if (val === SOCIAL_REVIEW_STEP || val === SOCIAL_DIGITAL_VIDEO_REVIEW) {
                roleName = SOCIAL_REVIEWER_ROLE;
            } else if (val === GAMES_REVIEW_STEP) {
                roleName = GAMES_REVIEWER_ROLE;
            } else if (val === DIGITAL_REVIEW_STEP) {
                roleName = DIGITAL_REVIEWER_ROLE;
            } else if (val === SAFETY_STEP) {
                roleName = SAFETY_ROLE;
            } else {
                // in case of BAC FINAL
                roleName = BAC_ADMIN_ROLE;
            }
            await store.dispatch('users/fetchUsersList', {
                params: {
                    page: 1,
                    limit: 1000,
                    rolename: roleName
                }
            });
        };

        watch(
            () => [submissionForm.routebackStep, submissionForm.currentActionName, submissionForm.legalStatusName, submissionForm.creativeStatusName],
            async () => {
                if (submissionForm.routebackStep) {
                    console.log('fetchUserListBasedOnReviewerSelected - routebackStep');
                    await fetchUserListBasedOnReviewerSelected(submissionForm.routebackStep);
                } else {
                    console.log('fetchUserListBasedOnReviewerSelected - currentStepName');
                    await fetchUserListBasedOnReviewerSelected(submissionForm.currentStepName);
                }
            }
        );

        watch(
            () => submissionForm.placeSampleHold,
            () => {
                if ([CONTRACTUAL_STAGE, ANNUALS_STAGE].includes(submissionForm.currentStageName)) {
                    if (submissionForm.placeSampleHold === 'N' || submissionForm.placeSafetyHold === 'N') {
                        submissionFormValidations.currentAction = '';
                        errorTextActionFieldBAC.value = '';
                    }
                    if (![RESUBMIT_WITH_CHANGES_STATUS, REJECT_STATUS, ROUTE_BACK_STATUS].includes(submissionForm.currentActionName) && (([BAC_SUPERVISOR_STEP].includes(submissionForm.currentStepName) && submissionForm.placeSampleHold === 'Y') || ([SAFETY_STEP].includes(submissionForm.currentStepName) && submissionForm.placeSafetyHold === 'Y'))) {
                        if ([SAFETY_STEP, BAC_SUPERVISOR_STEP].includes(submissionForm.currentStepName)) {
                            errorTextActionFieldBAC.value = 'Only Resubmit with changes or Reject is allowed because Safety (or) Sample hold is checked.';
                            submissionFormValidations.currentAction = REQUIRED_FIELD;
                        }
                    }
                }
            }
        );

        watch(
            () => submissionForm.placeSafetyHold,
            () => {
                if ([CONTRACTUAL_STAGE, ANNUALS_STAGE].includes(submissionForm.currentStageName)) {
                    if (submissionForm.placeSampleHold === 'N' || submissionForm.placeSafetyHold === 'N') {
                        submissionFormValidations.currentAction = '';
                        errorTextActionFieldBAC.value = '';
                    }

                    if ([SAFETY_STEP, BAC_SUPERVISOR_STEP].includes(submissionForm.currentStepName)) {
                        if (![RESUBMIT_WITH_CHANGES_STATUS, REJECT_STATUS, ROUTE_BACK_STATUS].includes(submissionForm.currentActionName) && (([BAC_SUPERVISOR_STEP].includes(submissionForm.currentStepName) && submissionForm.placeSampleHold === 'Y') || ([SAFETY_STEP].includes(submissionForm.currentStepName) && submissionForm.placeSafetyHold === 'Y'))) {
                            errorTextActionFieldBAC.value = 'Only Resubmit with changes or Reject is allowed because Safety (or) Sample hold is checked.';
                            submissionFormValidations.currentAction = REQUIRED_FIELD;
                        }
                    }
                }
            }
        );

        /* watcher added when we resubmit from bac to licensee step (currentActionValidation should be empty) */
        watch(
            () => submissionForm.currentStepName,
            () => {
                if (submissionForm.currentStepName === LICENSEE_STEP) {
                    submissionFormValidations.currentAction = '';
                    errorTextActionFieldBAC.value = '';
                }
            },
            { deep: true, immediate: true }
        );

        watch(
            () => submissionForm.currentActionName,
            () => {
                if ([CONTRACTUAL_STAGE, ANNUALS_STAGE].includes(submissionForm.currentStageName)) {
                    if (submissionForm.placeSampleHold === 'N' || submissionForm.placeSafetyHold === 'N') {
                        submissionFormValidations.currentAction = '';
                        errorTextActionFieldBAC.value = '';
                    }

                    if ([SAFETY_STEP, BAC_SUPERVISOR_STEP].includes(submissionForm.currentStepName)) {
                        if (![RESUBMIT_WITH_CHANGES_STATUS, REJECT_STATUS, ROUTE_BACK_STATUS].includes(submissionForm.currentActionName) && (([BAC_SUPERVISOR_STEP].includes(submissionForm.currentStepName) && submissionForm.placeSampleHold === 'Y') || ([SAFETY_STEP].includes(submissionForm.currentStepName) && submissionForm.placeSafetyHold === 'Y'))) {
                            errorTextActionFieldBAC.value = 'Only Resubmit with changes or Reject is allowed because Safety (or) Sample hold is checked.';
                            submissionFormValidations.currentAction = REQUIRED_FIELD;
                        } else {
                            submissionFormValidations.currentAction = '';
                            errorTextActionFieldBAC.value = '';
                        }
                    }
                }
            }
        );

        const submissionReviewers = computed(() => {
            const currentStep = __submissionSteps.value.find(s => s.stepKey === submissionForm.currentStep);
            const curStepWeight = currentStep?.stepWeight || 0;
            // console.log(`__submissionSteps ... ${JSON.stringify(__submissionSteps.value)}`);
            let reviewerArray = __submissionSteps.value.filter(e => {
                if (submissionForm.submissionTypeName === GENERIC_SUBMISSION_TYPE && ![SOCIAL_MEDIA_POST_METARIAL, SOCIAL_DIGITAL_VIDEO_REVIEW].includes(submissionForm.materialsSupplied) && currentStep.stepName === SOCIAL_REVIEW_STEP) {
                    return e.routebackEligible && submissionForm.currentStage === e.stageKey;
                } else {
                    return e.routebackEligible && e.stepWeight < curStepWeight && submissionForm.currentStage === e.stageKey;
                }
            });

            if (DPA_MATERIALS.includes(submissionForm.materialsSupplied)) {
                if (submissionForm.materialsSupplied === DPA_GOODS_MATERIAL) {
                    reviewerArray = reviewerArray.filter(e => ![GAMES_REVIEW_STEP, SOCIAL_REVIEW_STEP].includes(e.stepName));
                } else if ([DPA_GAMES_MATERIAL, DPA_UX_MATERIAL].includes(submissionForm.materialsSupplied)) {
                    reviewerArray = reviewerArray.filter(e => e.stepName !== SOCIAL_REVIEW_STEP);
                } else if ([DPA_STICKER_MATERIAL, DPA_AR_MATERIAL].includes(submissionForm.materialsSupplied)) {
                    if (currentStep.stepName !== BAC_FINAL_STEP || DPA_GOODS_MATERIAL === submissionForm.materialsSupplied) {
                        reviewerArray = reviewerArray.filter(e => e.stepName !== SOCIAL_REVIEW_STEP);
                    }
                    if (DPA_AR_MATERIAL !== submissionForm.materialsSupplied) {
                        reviewerArray = reviewerArray.filter(e => e.stepName !== GAMES_REVIEW_STEP);
                    }
                }
            } else {
                if ([SOCIAL_MEDIA_POST_METARIAL, SOCIAL_DIGITAL_VIDEO_REVIEW].includes(submissionForm.materialsSupplied)) {
                    reviewerArray = reviewerArray.filter(e => ![GAMES_REVIEW_STEP, DIGITAL_REVIEW_STEP].includes(e.stepName));
                } else {
                    reviewerArray = reviewerArray.filter(e => ![GAMES_REVIEW_STEP, DIGITAL_REVIEW_STEP, SOCIAL_REVIEW_STEP].includes(e.stepName));
                }
            }
            return reviewerArray;
        });

        const selectedStatusActionName = ref('');
        const submissionReviewerField = computed(() => {
            __submissionStatusList.value.forEach(list => {
                if (list.statusKey === submissionForm.legalStatus || list.statusKey === submissionForm.creativeStatus || list.statusKey === submissionForm.currentAction) {
                    selectedStatusActionName.value = list.statusName;
                }
            });

            if (submissionForm.currentStepName === SAFETY_STEP || (submissionForm.currentStepName === SOCIAL_REVIEW_STEP && [SOCIAL_MEDIA_POST_METARIAL, SOCIAL_DIGITAL_VIDEO_REVIEW].includes(submissionForm.materialsSupplied))) {
                return INVISIBLE;
            }

            if (submissionForm.currentActionName === ROUTE_BACK_STATUS || submissionForm.legalStatusName === ROUTE_BACK_STATUS || submissionForm.creativeStatusName === ROUTE_BACK_STATUS) {
                return REQUIRED;
            }

            return INVISIBLE;
        });

        // to do
        const submissionStatusListLegal = computed(() => {
            const newList = __submissionStatusList.value.filter(e => {
                if (e.isLegalVisible === 'Y' && submissionForm.currentStepName === LEGAL_REVIEW_STEP) {
                    return e;
                }
            });
            return newList;
        });

        const submissionStatusListCreative = computed(() => {
            const newList = __submissionStatusList.value.filter(e => {
                if (e.isCreativeVisible === 'Y' && submissionForm.currentStepName === CREATIVE_REVIEW_STEP) {
                    return e;
                }
            });

            return newList;
        });

        const submissionStatusListAction = computed(() => {
            const newList = __submissionStatusList.value.filter(__status => {
                // console.log(`__status ...${JSON.stringify(__status)}`);
                if (__status.isDpaVisible === 'Y' && submissionForm.currentStepName === DIGITAL_REVIEW_STEP) {
                    return __status;
                }
                if (__status.isBacVisible === 'Y' && submissionForm.currentStepName === BAC_REVIEW_STEP) {
                    if (!DPA_MATERIALS.includes(submissionForm.materialsSupplied) && ![SOCIAL_MEDIA_POST_METARIAL, SOCIAL_DIGITAL_VIDEO_REVIEW].includes(submissionForm.materialsSupplied)) {
                        return !__status.statusName.includes(ROUTE_BACK_STATUS);
                    }
                    return __status;
                }

                if (__status.isSupervisorVisible === 'Y' && submissionForm.currentStepName === BAC_SUPERVISOR_STEP) {
                    return __status;
                }

                if (__status.isSupervisorVisible === 'Y' && submissionForm.currentStepName === BAC_SUPERVISOR_STEP) {
                    return __status;
                }

                if (__status.isFinalVisible === 'Y' && submissionForm.currentStepName === BAC_FINAL_STEP) {
                    return __status;
                }

                if (__status.isSafetyVisible === 'Y' && submissionForm.currentStepName === SAFETY_STEP) {
                    return __status;
                }
                if (__status.isGamesVisible === 'Y' && submissionForm.currentStepName === GAMES_REVIEW_STEP) {
                    return __status;
                }

                if (__status.isSocialVisible === 'Y' && submissionForm.currentStepName === SOCIAL_REVIEW_STEP) {
                    if ([SOCIAL_MEDIA_POST_METARIAL, SOCIAL_DIGITAL_VIDEO_REVIEW].includes(submissionForm.materialsSupplied)) {
                        return !__status.statusName.includes(ROUTE_BACK_STATUS);
                    }
                    return __status;
                }

            });

            return newList;
        });

        // submission routing validations logic
        watch(
            () => submissionForm.materialsSupplied,
            () => {
                placeSampleHoldFieldState();
                placeSafetyHoldFieldState();
                safetyNeededFieldState();
                rushSubmissionFieldState();
                safetyCompletedFieldState();
                sendToFinalFieldState();
                skipToFinalFieldState();
                escalateToSupervisorFieldState();
            }
        );

        watch(
            () => [submissionForm.creativeStatusName, submissionForm.legalStatusName, submissionForm.currentActionName],
            () => {
                if ([ROUTE_BACK_STATUS, REJECT_STATUS, ASSIGN_TO_STATUS].includes(submissionForm.creativeStatusName) || [ROUTE_BACK_STATUS, REJECT_STATUS, ASSIGN_TO_STATUS].includes(submissionForm.currentActionName) || [ROUTE_BACK_STATUS, REJECT_STATUS, ASSIGN_TO_STATUS].includes(submissionForm.legalStatusName)) {
                    submissionForm.escalateToSupervisor = 'N';
                }
            }
        );

        // const samplePlaceHoldByCount = () => {
        //     if ([ANNUALS_STAGE, CONTRACTUAL_STAGE].includes(submissionForm.currentStageName) && submissionForm.currentStepName === LICENSEE_STEP) { // contractual and licensee step auto select sample hold.
        //         if (parseInt(submissionForm.totalSampleCount) < parseInt(submissionForm.contractualSampleAmount)) {
        //             submissionForm.placeSampleHold = 'Y';
        //         } else {
        //             submissionForm.placeSampleHold = 'N';
        //         }
        //     }
        // };

        // submission routing options logic
        const isLoading = ref(false);
        const fetchSubmissionRoutingOptions = async () => {
            // console.log(`>>>> fetchSubmissionRoutingOptions .. ${isLoading.value} ... ${submissionForm.submissionType}`)
            if (isLoading.value) return;
            try {
                isLoading.value = true;

                await Promise.all([
                    store.dispatch('baSubmissionRouting/fetchStages', {
                        params: {
                            submissionTypeKey: submissionForm.submissionType,
                            stageKey: submissionForm.currentStage
                        }
                    }),
                    store.dispatch('baSubmissionRouting/fetchSteps', {
                        params: {
                            submissionTypeKey: submissionForm.submissionType
                        }
                    }),
                    store.dispatch('baSubmissionRouting/fetchStatusList', {
                        params: {
                            submissionTypeKey: submissionForm.submissionType
                        }
                    }),
                    store.dispatch('baSubmissionRouting/fetchPossibleStages', {
                        params: {
                            submissionTypeKey: submissionForm.submissionType,
                            stageKey: submissionForm.currentStage
                        }
                    }),
                    store.dispatch('users/fetchUsersList', {
                        params: {}
                    })
                ]);
            } catch (err) {
                console.error(err);
            } finally {
                isLoading.value = false;
            }
        };

        onMounted(async () => {
            await fetchSubmissionRoutingOptions();

            // samplePlaceHoldByCount();
        });

        // watch(
        //     () => submissionForm.totalSampleCount,
        //     () => {
        //         // samplePlaceHoldByCount();
        //     },
        //     { deep: true, immediate: true }
        // );

        watch(
            () => submissionForm.submissionType,
            async (__new, __old) => {
                if (__new) await fetchSubmissionRoutingOptions();
            },
            { deep: true, immediate: true }
        );

        const placeSampleHoldFieldState = () => {
            if ([PRESS_RELEASE_SUBMISSION_TYPE, INTERACTIVE_SUBMISSION_TYPE, PRINT_ON_DEMAND_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                if ([BAC_REVIEW_STEP, LEGAL_REVIEW_STEP, CREATIVE_REVIEW_STEP, BAC_SUPERVISOR_STEP, BAC_FINAL_STEP, SAFETY_STEP].includes(submissionForm.currentStepName)) {
                    return INVISIBLE;
                }
            } else if ([OPA_SUBMISSION_TYPE, STANDARD_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                if ([BAC_REVIEW_STEP, BAC_SUPERVISOR_STEP].includes(submissionForm.currentStepName)) {
                    return EDITABLE;
                } else {
                    return VIEW_ONLY;
                }
            } else if ([GENERIC_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                if ([CONCEPT_STAGE, REVISED_STAGE].includes(submissionForm.currentStageName)) {
                    return INVISIBLE;
                } else if ([PRE_PRODUCTION_STAGE, PRODUCTION_STAGE, CONTRACTUAL_STAGE, ANNUALS_STAGE].includes(submissionForm.currentStageName)) {
                    if ([BAC_REVIEW_STEP, BAC_SUPERVISOR_STEP].includes(submissionForm.currentStepName)) {
                        return EDITABLE;
                    } else {
                        return VIEW_ONLY;
                    }
                }
            }
            return INVISIBLE;
        };

        watch(
            () => submissionForm.placeSafetyHold,
            () => {
                if (submissionForm.placeSafetyHold === 'Y') {
                    submissionForm.isSafetyCompleted = 'N';
                }
            }
        );

        const placeSafetyHoldFieldState = () => {
            if ([PRESS_RELEASE_SUBMISSION_TYPE, INTERACTIVE_SUBMISSION_TYPE, PRINT_ON_DEMAND_SUBMISSION_TYPE, OPA_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                if ([BAC_REVIEW_STEP, LEGAL_REVIEW_STEP, CREATIVE_REVIEW_STEP, BAC_SUPERVISOR_STEP, BAC_FINAL_STEP, SAFETY_STEP].includes(submissionForm.currentStepName)) {
                    return INVISIBLE;
                }
            } else if ([STANDARD_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                if ([BAC_FINAL_STEP].includes(submissionForm.currentStepName)) {
                    return VIEW_ONLY;
                } else {
                    if (submissionForm.isSafetyNeeded === 'Y') {
                        return EDITABLE;
                    } else {
                        return VIEW_ONLY;
                    }
                }
            } else if ([GENERIC_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                if ([CONCEPT_STAGE, REVISED_STAGE].includes(submissionForm.currentStageName)) {
                    return INVISIBLE;
                } else if ([PRE_PRODUCTION_STAGE, PRODUCTION_STAGE, CONTRACTUAL_STAGE, ANNUALS_STAGE].includes(submissionForm.currentStageName)) {
                    if ([SAFETY_STEP, BAC_SUPERVISOR_STEP].includes(submissionForm.currentStepName)) {
                        if (submissionForm.isSafetyNeeded === 'Y') {
                            return EDITABLE;
                        } else {
                            submissionForm.placeSafetyHold = 'N';
                            return VIEW_ONLY;
                        }
                    } else {
                        return VIEW_ONLY;
                    }
                }
            }
            return INVISIBLE;
        };
        const showSafetyNeededModal = ref(false);
        const setSafetyNeededModalVisibility = (visibility) => {
            if (submissionForm.submissionStatus !== 'Draft' && submissionForm.currentStepName !== LICENSEE_STEP) {
                showSafetyNeededModal.value = visibility;
            }
        };

        const resetSafetyNeededToYes = () => {
            submissionForm.isSafetyNeeded = 'Y';
            setSafetyNeededModalVisibility(false);
        };

        const confirmedSafetyNeededCanBeNo = () => {
            setSafetyNeededModalVisibility(false);
            submissionForm.isSafetyNeeded = 'N';
            if (submissionForm.placeSafetyHold === 'Y' || submissionForm.isSafetyCompleted === 'Y') {
                submissionForm.placeSafetyHold = 'N';
                submissionForm.isSafetyCompleted = 'N';
                if (notifiersEnabled.value) notificationsStack.value.push({
                    type: WARNING,
                    message: 'Safety Hold and Safety Completed flags has been cleared from this item'
                });
            }
        };
        watch(
            () => submissionForm.isSafetyNeeded,
            (__newVal, __oldVal) => {
                if (__newVal === 'N' && __oldVal === 'Y') {
                    if (notifiersEnabled.value) setSafetyNeededModalVisibility(true);
                }
            }
        );

        const safetyNeededFieldState = () => {
            if ([PRESS_RELEASE_SUBMISSION_TYPE, OPA_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                if ([BAC_REVIEW_STEP, LEGAL_REVIEW_STEP, CREATIVE_REVIEW_STEP, BAC_SUPERVISOR_STEP, BAC_FINAL_STEP, SAFETY_STEP].includes(submissionForm.currentStepName)) {
                    return INVISIBLE;
                }
            } else if ([PRINT_ON_DEMAND_SUBMISSION_TYPE, INTERACTIVE_SUBMISSION_TYPE, GENERIC_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                if ([BAC_REVIEW_STEP, LEGAL_REVIEW_STEP, CREATIVE_REVIEW_STEP, BAC_SUPERVISOR_STEP, BAC_FINAL_STEP, SAFETY_STEP, SOCIAL_REVIEW_STEP].includes(submissionForm.currentStepName)) {
                    return EDITABLE;
                }
            } else if ([STANDARD_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                if ([BAC_FINAL_STEP].includes(submissionForm.currentStepName)) {
                    return VIEW_ONLY;
                } else {
                    return EDITABLE;
                }
            }
            return INVISIBLE;
        };
        const rushSubmissionFieldState = () => {
            if (LICENSEE_STEP === submissionForm.currentStepName || !submissionForm.currentStepName) {
                return INVISIBLE;
            } else {
                return EDITABLE;
            }
        };

        const escalateToSupervisorFieldState = () => {
            if (submissionForm.currentStepName === BAC_SUPERVISOR_STEP) {
                return INVISIBLE;
            } else {
                if ([ROUTE_BACK_STATUS, REJECT_STATUS, ASSIGN_TO_STATUS, RESUBMIT_WITH_CHANGES_STATUS].includes(submissionForm.creativeStatusName) || [ROUTE_BACK_STATUS, REJECT_STATUS, ASSIGN_TO_STATUS, RESUBMIT_WITH_CHANGES_STATUS].includes(submissionForm.currentActionName) || [ROUTE_BACK_STATUS, REJECT_STATUS, ASSIGN_TO_STATUS, RESUBMIT_WITH_CHANGES_STATUS].includes(submissionForm.legalStatusName)) {
                    if (submissionForm.escalateToSupervisor === 'Y') {
                        submissionForm.escalateToSupervisor = 'N';
                        if (notifiersEnabled.value) notificationsStack.value.push({
                            type: WARNING,
                            message: 'Escalate to Supervisor has been disabled for this selection.'
                        });
                    }
                    return VIEW_ONLY;
                } else {
                    return EDITABLE;
                }
            }
        };

        const showIsSafetyCompletedConfirmationModal = ref(false);
        const setIsSafetyCompletedConfirmationVisibility = (visibility) => {
            showIsSafetyCompletedConfirmationModal.value = visibility;
        };
        const closeIsSafetyCompletedConfirmationModal = () => {
            submissionForm.isSafetyCompleted = 'Y';
            setIsSafetyCompletedConfirmationVisibility(false);
        };

        const hideIsSafetyCompletedConfirmationModal = () => {
            setIsSafetyCompletedConfirmationVisibility(false);
        };
        const confirmIsSafetyCompletedConfirmationModal = () => {
            setIsSafetyCompletedConfirmationVisibility(false);
            submissionForm.isSafetyCompleted = 'N';
        };

        watch(
            () => submissionForm.isSafetyCompleted,
            () => {
                // if (submissionForm.isSafetyCompleted === 'N' && ) {
                if (submissionForm.isSafetyCompleted === 'N' && [CONTRACTUAL_STAGE, ANNUALS_STAGE].includes(submissionForm.currentStageName)) {
                    if (notifiersEnabled.value) setIsSafetyCompletedConfirmationVisibility(true);
                    if (submissionForm.currentStepName === SAFETY_STEP) {
                        // modified the text accordingly
                        errorTextActionFieldBAC.value = 'Please select Safety Completed flag or you must select Place Safety Hold and Resubmit with Changes.';
                        submissionFormValidations.currentAction = REQUIRED_FIELD;
                    }
                } else if (submissionForm.isSafetyCompleted === 'Y') {
                    errorTextActionFieldBAC.value = '';
                    submissionFormValidations.currentAction = '';
                    if (submissionForm.placeSafetyHold === 'Y') {
                        submissionForm.placeSafetyHold = 'N';
                    }
                }
            }
        );

        const safetyCompletedFieldState = () => {
            if (submissionForm.currentStepName === SAFETY_STEP) {
                return EDITABLE;
            } else if (submissionForm.submissionTypeName === PRESS_RELEASE_SUBMISSION_TYPE) {
                return INVISIBLE;
            } else if ([INTERACTIVE_SUBMISSION_TYPE, PRINT_ON_DEMAND_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName) && [BAC_REVIEW_STEP].includes(submissionForm.currentStepName)) {
                return VIEW_ONLY;
            } else if (submissionForm.submissionTypeName === INTERACTIVE_SUBMISSION_TYPE && submissionForm.currentStepName === SAFETY_STEP) {
                return EDITABLE;
            } else if ([OPA_SUBMISSION_TYPE, STANDARD_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName) && [BAC_REVIEW_STEP, LEGAL_REVIEW_STEP, CREATIVE_REVIEW_STEP, BAC_SUPERVISOR_STEP, BAC_FINAL_STEP].includes(submissionForm.currentStepName)) {
                return VIEW_ONLY;
            } else if ([GENERIC_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                if (submissionForm.materialsSupplied === PREMIUM_GWPS_PRODUCTS_METARIAL) {
                    if ([BAC_REVIEW_STEP, LEGAL_REVIEW_STEP, CREATIVE_REVIEW_STEP, BAC_SUPERVISOR_STEP, BAC_FINAL_STEP].includes(submissionForm.currentStepName)) {
                        return VIEW_ONLY;
                    }
                } else {
                    if ([CONCEPT_STAGE, REVISED_STAGE].includes(submissionForm.currentStageName)) {
                        if (submissionForm.currentStepName === BAC_REVIEW_STEP) {
                            return VIEW_ONLY;
                        }
                    }
                }
            }
            return INVISIBLE;
        };

        watch(
            () => submissionForm.escalateToSupervisor,
            () => {
                if (submissionForm.escalateToSupervisor === 'Y') {
                    submissionForm.sendToFinal = 'N';
                }
            }
        );
        watch(
            () => submissionForm.sendToFinal,
            () => {
                if (submissionForm.sendToFinal === 'Y') {
                    submissionForm.escalateToSupervisor = 'N';
                    if (notifiersEnabled.value && ![APPROVE_STATUS, APPROVE_WITH_CHANGES_STATUS].includes(submissionForm.currentActionName)) {
                        notificationsStack.value.push({
                            type: ERROR,
                            message: 'Only Approve is allowed because Send to Safety/Final is checked.'
                        });
                    }
                }
            }
        );

        const sendToFinalFieldState = () => {
            if ([STANDARD_SUBMISSION_TYPE, OPA_SUBMISSION_TYPE, GENERIC_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName) && submissionForm.currentStepName === BAC_REVIEW_STEP && [CONTRACTUAL_STAGE, ANNUALS_STAGE].includes(submissionForm.currentStageName)) {
                if ([REJECT_STATUS, RESUBMIT_WITH_CHANGES_STATUS].includes(submissionForm.currentActionName) || [REJECT_STATUS, RESUBMIT_WITH_CHANGES_STATUS].includes(submissionForm.legalStatusName) || [REJECT_STATUS, RESUBMIT_WITH_CHANGES_STATUS].includes(submissionForm.creativeStatusName)) {
                    submissionForm.sendToFinal = 'N';
                    return VIEW_ONLY;
                } else {
                    return EDITABLE;
                }
            } else {
                return INVISIBLE;
            }
        };

        watch(
            () => submissionForm.skipFinal,
            () => {
                if (submissionForm.skipFinal === 'Y' && ![APPROVE_STATUS, APPROVE_WITH_CHANGES_STATUS].includes(submissionForm.currentActionName)) {
                    if (notifiersEnabled.value) notificationsStack.value.push({
                        type: ERROR,
                        message: 'Only Approve or Approve with Changes is allowed as Skip BAC Final is selected.'
                    });
                }
            }
        );

        const skipToFinalFieldState = () => {
            if (submissionForm.currentStepName === BAC_SUPERVISOR_STEP) {
                if ((submissionForm.currentStepName === BAC_SUPERVISOR_STEP && (!submissionForm.legalStatus || !submissionForm.creativeStatus))) {
                    submissionForm.skipFinal = 'N';
                    return VIEW_ONLY;
                }
                if ([ROUTE_BACK_STATUS, REJECT_STATUS, RESUBMIT_WITH_CHANGES_STATUS].includes(submissionForm.currentActionName) || [ROUTE_BACK_STATUS, REJECT_STATUS, RESUBMIT_WITH_CHANGES_STATUS].includes(submissionForm.legalStatusName) || [ROUTE_BACK_STATUS, REJECT_STATUS, RESUBMIT_WITH_CHANGES_STATUS].includes(submissionForm.creativeStatusName)) {
                    if (submissionForm.skipFinal === 'Y') {
                        submissionForm.skipFinal = 'N';
                        if (notifiersEnabled.value) notificationsStack.value.push({
                            type: WARNING,
                            message: 'Skip to final has been disabled for this selection.'
                        });
                    }
                    return VIEW_ONLY;
                } else {
                    return EDITABLE;
                }
            } else {
                return INVISIBLE;
            }
        };
 

        const currentStageFieldState = computed(() => {
            if (!userPermissions.value.includes(SUBMISSION_FORM_SUBMISSION_ROUTING_PERMISSION)) {
                return INVISIBLE;
            } else if ([BAC_SUPERVISOR_ROLE, BAC_REVIEWER_ROLE, BAC_ADMIN_ROLE].some(role => userRoles.value.includes(role)) && [PRODUCTION_STAGE, CONTRACTUAL_STAGE, ANNUALS_STAGE].includes(submissionForm.currentStepName)) {
                // NOTE : CURRENTLY THIS RULE IS NOT BEING USED .. checking for "currentStepName" instead of "currentStageName"
                return EDITABLE;
            } else if (
                // New Rule for making Current Stage editable
                [STANDARD_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName) &&
              ![PRODUCTION_STAGE, CONTRACTUAL_STAGE, ANNUALS_STAGE, FINAL_STAGE, REVISED_STAGE].includes(submissionForm.currentStageName) &&
              [BAC_REVIEW_STEP, BAC_SUPERVISOR_STEP].includes(submissionForm.currentStepName)
            ) {
                return EDITABLE;
            } else {
                return LICENSEE_STEP === submissionForm.currentStepName || !submissionForm.currentStepName ? INVISIBLE : VIEW_ONLY;
            }
        });
        const nextStageFieldState = computed(() => {
            if (!userPermissions.value.includes(SUBMISSION_FORM_SUBMISSION_ROUTING_PERMISSION)) {
                return INVISIBLE;
            } else if (['Rejected', 'Cancelled'].includes(submissionForm.submissionStatus)) {
                return INVISIBLE;
            } else if (submissionForm.currentStepName === BAC_SUPERVISOR_STEP) {
                return EDITABLE;
            } else if (LICENSEE_STEP !== submissionForm.currentStepName) {
                return VIEW_ONLY;
            } else {
                return INVISIBLE;
            }
        });

        const errorLegalNextStage = ref('');
        const listNameLegalReview = ref('');
        const currentStepFieldState = computed(() => LICENSEE_STEP === submissionForm.currentStepName || !submissionForm.currentStepName ? INVISIBLE : VIEW_ONLY);
        const nextStepFieldState = computed(() => {
            if (LICENSEE_STEP === submissionForm.currentStepName || !submissionForm.currentStepName) {
                return INVISIBLE;
            } else if (['Rejected', 'Cancelled'].includes(submissionForm.submissionStatus)) {
                return INVISIBLE;
            } else {
                return VIEW_ONLY;
            };
        });

        const legalNextStageFieldState = computed(() => {
            __submissionStages.value.forEach(stage => {
                if (stage.stageKey === submissionForm.legalNextStage) {
                    submissionForm.legalNextStageName = stage.stageName;
                }
                if (submissionForm.legalNextStage === '') {
                    submissionForm.legalNextStageName = '';
                }
            });

            if ([ROUTE_BACK_STATUS, RESUBMIT_WITH_CHANGES_STATUS, ASSIGN_TO_STATUS, REJECT_STATUS].includes(submissionForm.legalStatusName)) {
                submissionForm.legalNextStage = submissionForm.currentStage;
                submissionForm.legalNextStageName = submissionForm.currentStageName;
            }
            if (!userPermissions.value.includes(SUBMISSION_FORM_SUBMISSION_ROUTING_PERMISSION) || LICENSEE_STEP === submissionForm.currentStepName || !submissionForm.currentStepName) {
                return INVISIBLE;
            } else if (['Rejected', 'Cancelled'].includes(submissionForm.submissionStatus)) {
                return INVISIBLE;
            } else if ([BAC_REVIEW_STEP, CREATIVE_REVIEW_STEP, BAC_FINAL_STEP, BAC_SUPERVISOR_STEP, GAMES_REVIEW_STEP ].includes(submissionForm.currentStepName)) {
                return VIEW_ONLY;
            } else if (submissionForm.currentStepName === SAFETY_STEP) {
                return INVISIBLE;
            } else if ([SOCIAL_REVIEW_STEP, DIGITAL_REVIEW_STEP].includes(submissionForm.currentStepName)) {
                return VIEW_ONLY;
            } else {
                if ([PRESS_RELEASE_SUBMISSION_TYPE, OPA_SUBMISSION_TYPE, PRINT_ON_DEMAND_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                    submissionForm.legalNextStage = '--NA--';
                    submissionForm.legalNextStageName = '--NA--';
                    return VIEW_ONLY;
                } else if (submissionForm.legalNextStageName === submissionForm.currentStageName && submissionForm.currentStepName === LEGAL_REVIEW_STEP) {
                    if (submissionForm.submissionTypeName === STANDARD_SUBMISSION_TYPE && submissionForm.currentStageName === ANNUALS_STAGE) {
                        return EDITABLE;
                    } else {
                        return REQUIRED;
                    }
                }
                if ([ROUTE_BACK_STATUS, RESUBMIT_WITH_CHANGES_STATUS, ASSIGN_TO_STATUS, REJECT_STATUS].includes(submissionForm.legalStatusName)) {
                    return VIEW_ONLY;
                }
                return REQUIRED;
            }
        });

        const legalStatusFieldState = computed(() => {
            if (submissionForm.currentStepName === LEGAL_REVIEW_STEP) {
                __submissionStatusList.value.forEach(list => {
                    if (list.statusKey === submissionForm.legalStatus) {
                        listNameLegalReview.value = list.statusName;
                        submissionForm.submissionStatusKeyName = listNameLegalReview.value;
                        submissionForm.legalStatusName = list.statusName;
                    }
                    if (submissionForm.legalStatus === '') {
                        submissionForm.legalStatusName = '';
                    }
                });
            }
            if (!userPermissions.value.includes(SUBMISSION_FORM_SUBMISSION_ROUTING_PERMISSION) || LICENSEE_STEP === submissionForm.currentStepName || !submissionForm.currentStepName) {
                return INVISIBLE;
            } else if (submissionForm.currentStepName === BAC_REVIEW_STEP || submissionForm.currentStepName === CREATIVE_REVIEW_STEP || submissionForm.currentStepName === BAC_FINAL_STEP || submissionForm.currentStepName === BAC_SUPERVISOR_STEP || submissionForm.currentStepName === GAMES_REVIEW_STEP) {
                return VIEW_ONLY;
            } else if (submissionForm.currentStepName === SAFETY_STEP) {
                return INVISIBLE;
            } else if ([SOCIAL_REVIEW_STEP, DIGITAL_REVIEW_STEP].includes(submissionForm.currentStepName)) {
                return VIEW_ONLY;
            } else {
                submissionStatusListLegal.value.forEach(list => {
                    if (list.statusKey === submissionForm.legalStatus) {
                        submissionForm.legalStatusName = list.statusName;
                    }
                });
                return REQUIRED;
            }
        });
        const errorCreativeNextStage = ref('');
        const listNameCreativeReview = ref('');
        const creativeNextStageFieldState = computed(() => {
            __submissionStages.value.forEach(stage => {
                if (stage.stageKey === submissionForm.creativeNextStage) {
                    submissionForm.creativeNextStageName = stage.stageName;
                }
                if (submissionForm.creativeNextStage === '') {
                    submissionForm.creativeNextStageName = '';
                }
            });
            if ([ROUTE_BACK_STATUS, RESUBMIT_WITH_CHANGES_STATUS, ASSIGN_TO_STATUS, REJECT_STATUS].includes(submissionForm.creativeStatusName)) {
                submissionForm.creativeNextStage = submissionForm.currentStage;
                submissionForm.creativeNextStageName = submissionForm.currentStageName;
            }
            if (!userPermissions.value.includes(SUBMISSION_FORM_SUBMISSION_ROUTING_PERMISSION) || LICENSEE_STEP === submissionForm.currentStepName || !submissionForm.currentStepName) {
                return INVISIBLE;
            } else if (['Rejected', 'Cancelled'].includes(submissionForm.submissionStatus)) {
                return INVISIBLE;
            } else if (submissionForm.currentStepName === BAC_REVIEW_STEP || submissionForm.currentStepName === LEGAL_REVIEW_STEP || submissionForm.currentStepName === BAC_FINAL_STEP || submissionForm.currentStepName === BAC_SUPERVISOR_STEP || submissionForm.currentStepName === GAMES_REVIEW_STEP) {
                return VIEW_ONLY;
            } else if (submissionForm.currentStepName === SAFETY_STEP) {
                return INVISIBLE;
            } else if ([SOCIAL_REVIEW_STEP, DIGITAL_REVIEW_STEP].includes(submissionForm.currentStepName)) {
                return VIEW_ONLY;
            } else if (submissionForm.currentStepName === CREATIVE_REVIEW_STEP) {
                if ([PRESS_RELEASE_SUBMISSION_TYPE, OPA_SUBMISSION_TYPE, PRINT_ON_DEMAND_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                    submissionForm.legalNextStage = '--NA--';
                    submissionForm.legalNextStageName = '--NA--';
                    submissionForm.creativeNextStage = '--NA--';
                    submissionForm.creativeNextStageName = '--NA--';
                    return VIEW_ONLY;
                } else if (submissionForm.creativeNextStageName === submissionForm.currentStageName && submissionForm.currentStepName === CREATIVE_REVIEW_STEP) {
                    if (submissionForm.submissionTypeName === STANDARD_SUBMISSION_TYPE && submissionForm.currentStageName === ANNUALS_STAGE) {
                        return EDITABLE;
                    } else {
                        return REQUIRED;
                    }
                }
                if (submissionForm.creativeStatusName === ROUTE_BACK_STATUS || submissionForm.creativeStatusName === RESUBMIT_WITH_CHANGES_STATUS || submissionForm.creativeStatusName === ASSIGN_TO_STATUS) {
                    return VIEW_ONLY;
                }
                return REQUIRED;
            } else {
                return EDITABLE;
            }
        });
        const creativeStatusFieldState = computed(() => {
            if (submissionForm.currentStepName === CREATIVE_REVIEW_STEP) {
                __submissionStatusList.value.forEach(list => {
                    if (list.statusKey === submissionForm.creativeStatus) {
                        listNameCreativeReview.value = list.statusName;
                        submissionForm.submissionStatusKeyName = listNameCreativeReview.value;
                        submissionForm.creativeStatusName = list.statusName;
                    }
                    if (submissionForm.creativeStatus === '') {
                        submissionForm.creativeStatusName = '';
                    }
                });
            }
            if (!userPermissions.value.includes(SUBMISSION_FORM_SUBMISSION_ROUTING_PERMISSION) || LICENSEE_STEP === submissionForm.currentStepName || !submissionForm.currentStepName || submissionForm.currentStepName === SAFETY_STEP) {
                return INVISIBLE;
            } else if ([BAC_REVIEW_STEP, LEGAL_REVIEW_STEP, BAC_FINAL_STEP, BAC_SUPERVISOR_STEP, GAMES_REVIEW_STEP].includes(submissionForm.currentStepName)) {
                return VIEW_ONLY;
            } else if ([SOCIAL_REVIEW_STEP, DIGITAL_REVIEW_STEP].includes(submissionForm.currentStepName)) {
                return VIEW_ONLY;
            } else {
                return REQUIRED;
            }
        });
        const submissionStatusFieldState = computed(() => {
            if (submissionForm.currentStepName === BAC_SUPERVISOR_STEP) {
                return INVISIBLE;
            } else {
                return VIEW_ONLY;
            }
        });

        const reviewerRoles = [
            BA_ROLES.BAC_REVIEWER_ROLE,
            BA_ROLES.LEGAL_ROLE,
            BA_ROLES.CREATIVE_ROLE,
            BA_ROLES.SAFETY_ROLE,
            BA_ROLES.BAC_ADMIN_ROLE,
            BA_ROLES.BAC_FINAL_ROLE,
            BA_ROLES.BAC_SUPERVISOR_ROLE,
            BA_ROLES.SOCIAL_REVIEWER_ROLE,
            BA_ROLES.BA_ADMIN_ROLE,
            BA_ROLES.SKU_ADMIN_ROLE
        ];

        const submissionRoutingSectionState = computed(() => {
            const tmpRoles = ((proxyUser.value && proxyUser.value?.roles) || []).filter(r => reviewerRoles.includes(r));

            if (submissionForm.isAdminEdit) {
                return EDITABLE;
            } else if (proxyUser.value && tmpRoles.length === 0) {
                return INVISIBLE;
            } else {
                return ((userRoles.value.includes(LICENSEE_ROLE) && userRoles.value.length === 1) || !submissionForm.currentStepName || LICENSEE_STEP === submissionForm.currentStepName) ? INVISIBLE : EDITABLE;
            }
        });
        const listNameCurrentAction = ref('');
        const currentActionFieldState = computed(() => {
            if (!userPermissions.value.includes(SUBMISSION_FORM_SUBMISSION_ROUTING_PERMISSION) || !submissionForm.currentStepName || [LICENSEE_STEP, LEGAL_REVIEW_STEP, CREATIVE_REVIEW_STEP].includes(submissionForm.currentStepName)) {
                return INVISIBLE;
            } else if (['Rejected', 'Cancelled'].includes(submissionForm.submissionStatus)) {
                return INVISIBLE;
            } else if ([BAC_REVIEW_STEP, SAFETY_STEP, BAC_SUPERVISOR_STEP, BAC_FINAL_STEP, SOCIAL_REVIEW_STEP, GAMES_REVIEW_STEP, DIGITAL_REVIEW_STEP].includes(submissionForm.currentStepName)) {
                submissionStatusListAction.value.forEach(list => {
                    if (list.statusKey === submissionForm.currentAction) {
                        listNameCurrentAction.value = list.statusName;
                        submissionForm.submissionStatusKeyName = listNameCurrentAction.value;
                        submissionForm.currentActionName = list.statusName;
                    }

                    if (submissionForm.currentAction === '') {
                        submissionForm.currentActionName = '';
                    }
                });
                return REQUIRED;
            } else {
                return VIEW_ONLY;
            }
        });

        watch(
            () => [listNameLegalReview.value, listNameCurrentAction.value, listNameCreativeReview.value],
            () => {
                if (![ASSIGN_TO_STATUS, ROUTE_BACK_STATUS].includes(listNameCurrentAction.value) || ![ASSIGN_TO_STATUS, ROUTE_BACK_STATUS].includes(listNameLegalReview.value) || ![ASSIGN_TO_STATUS, ROUTE_BACK_STATUS].includes(listNameCreativeReview.value)) {
                    submissionForm.routebackStep = '';
                    submissionForm.assignToUser = '';
                }
            }
        );

        const submissionUserFieldState = computed(() => {
            if (submissionForm.routebackStep || submissionForm.currentActionName === ASSIGN_TO_STATUS || submissionForm.legalStatusName === ASSIGN_TO_STATUS || submissionForm.creativeStatusName === ASSIGN_TO_STATUS) {
                return EDITABLE;
            } else {
                return INVISIBLE;
            }
        });

        watch(
            () => creativeNextStageFieldState.value,
            () => {
                submissionForm.routebackStep = creativeNextStageFieldState.value === VIEW_ONLY ? submissionForm.routebackStep : null;
                submissionForm.assignToUser = creativeNextStageFieldState.value === VIEW_ONLY ? submissionForm.routebackStep : null;
            }
        );

        watch(
            () => [submissionForm.legalStatus, submissionForm.legalNextStage, submissionForm.creativeStatus, submissionForm.creativeNextStage, submissionForm.currentAction, submissionForm.routebackStep],
            () => {
                submissionFormValidations.legalStatus = legalStatusFieldState.value === REQUIRED && !submissionForm.legalStatus ? REQUIRED_FIELD : '';
                submissionFormValidations.legalNextStage = legalNextStageFieldState.value === REQUIRED && !submissionForm.legalNextStage ? REQUIRED_FIELD : '';
                submissionFormValidations.creativeStatus = creativeStatusFieldState.value === REQUIRED && !submissionForm.creativeStatus ? REQUIRED_FIELD : '';
                submissionFormValidations.creativeNextStage = creativeNextStageFieldState.value === REQUIRED && !submissionForm.creativeNextStage ? REQUIRED_FIELD : '';
                submissionFormValidations.currentAction = currentActionFieldState.value === REQUIRED && !submissionForm.currentAction ? REQUIRED_FIELD : '';
                submissionFormValidations.routebackStep = submissionReviewerField.value === REQUIRED && !submissionForm.routebackStep ? REQUIRED_FIELD : '';
                submissionForm.legalNextStageName = submissionForm.legalNextStageName !== '' ? submissionForm.legalNextStageName : '';
                submissionForm.creativeNextStageName = submissionForm.creativeNextStageName !== '' ? submissionForm.creativeNextStageName : '';
            },
            {
                deep: true,
                immediate: true
            }
        );

        watch(
            () => submissionForm.currentAction,
            () => {
                currentActionFieldErrorState();
            }
        );

        onMounted(() => {
            currentActionFieldErrorState();
        });

        const currentActionFieldErrorState = () => {
            if ([APPROVE_STATUS, APPROVE_WITH_CHANGES_STATUS].includes(submissionForm.currentActionName)) {
                if ([BAC_SUPERVISOR_STEP, BAC_FINAL_STEP].includes(submissionForm.currentStepName)) {
                    if ([CONTRACTUAL_STAGE, ANNUALS_STAGE].includes(submissionForm.currentStageName)) {
                        if (!submissionForm.creativeStatusName || !submissionForm.legalStatusName) {
                            errorTextActionFieldBAC.value = 'You can not select Approve or Approve with changes, when legal status or creative status is empty.';
                            submissionFormValidations.currentAction = REQUIRED_FIELD;
                            return REQUIRED;
                        } else if ([SAFETY_STEP, BAC_SUPERVISOR_STEP].includes(submissionForm.currentStepName)) {
                            if (([BAC_SUPERVISOR_STEP].includes(submissionForm.currentStepName) && submissionForm.placeSampleHold === 'Y') ||
                                ([SAFETY_STEP].includes(submissionForm.currentStepName) && submissionForm.placeSafetyHold === 'Y')) {
                                errorTextActionFieldBAC.value = 'Only Resubmit with changes or Reject is allowed because Safety (or) Sample hold is checked.';
                                submissionFormValidations.currentAction = REQUIRED_FIELD;
                                return REQUIRED;
                            }
                        } else {
                            errorTextActionFieldBAC.value = '';
                            return INVISIBLE;
                        }
                    }
                } else if (submissionForm.currentStepName === SAFETY_STEP) {
                    if (submissionForm.isSafetyCompleted === 'N' && [CONTRACTUAL_STAGE, ANNUALS_STAGE].includes(submissionForm.currentStageName)) {
                        // modified the text accordingly
                        errorTextActionFieldBAC.value = 'Please select Safety Completed flag or you must select Place Safety Hold and Resubmit with Changes.';
                        submissionFormValidations.currentAction = REQUIRED_FIELD;
                        return REQUIRED;
                    }
                } else {
                    errorTextActionFieldBAC.value = '';
                    return INVISIBLE;
                }
            } else {
                errorTextActionFieldBAC.value = '';
                return INVISIBLE;
            }
        };

        const filterNextStagesForLegalAndCreative = (stages) => {
            if (!stages || !stages.length) return stages;
            if (!submissionForm || submissionForm.submissionTypeName !== STANDARD_SUBMISSION_TYPE || submissionForm.materialsSupplied !== 'Digital Goods') return stages;

            const tmp = stages.filter(s =>
                s.stageName === ANNUALS_STAGE ||
            s.stageName === CONTRACTUAL_STAGE ||
            (
                s.stageName === CONCEPT_STAGE &&
              (!submissionForm.currentStageName || submissionForm.currentStageName === CONCEPT_STAGE)
            )
            );
            return tmp;
        };

        watch(
            () => [submissionForm.legalNextStageName, submissionForm.legalStatusName],
            () => {
                if (
                    [APPROVE_STATUS, APPROVE_WITH_CHANGES_STATUS].includes(submissionForm.legalStatusName) && submissionForm.legalNextStageName === submissionForm.currentStageName
                ) {
                    if (submissionForm.submissionTypeName === STANDARD_SUBMISSION_TYPE && submissionForm.currentStageName !== ANNUALS_STAGE) {
                        errorLegalNextStage.value = 'Please select a different value for next stage other than the current stage.';
                        submissionFormValidations.legalNextStage = REQUIRED_FIELD;
                    } else if (submissionForm.submissionTypeName === GENERIC_SUBMISSION_TYPE && (submissionForm.currentStageName !== ANNUALS_STAGE || submissionForm.currentStageName !== FINAL_STAGE)) {
                        errorLegalNextStage.value = 'Please select a different value for next stage other than the current stage.';
                        submissionFormValidations.legalNextStage = REQUIRED_FIELD;
                    } else if (submissionForm.submissionTypeName === INTERACTIVE_SUBMISSION_TYPE && submissionForm.currentStageName !== FINAL_STAGE) {
                        errorLegalNextStage.value = 'Please select a different value for next stage other than the current stage.';
                        submissionFormValidations.legalNextStage = REQUIRED_FIELD;
                    }
                }
            },
            {
                deep: true,
                immediate: true
            }
        );

        watch(
            () => [submissionForm.creativeNextStageName, submissionForm.creativeStatusName],
            () => {
                if (
                    [APPROVE_STATUS, APPROVE_WITH_CHANGES_STATUS].includes(submissionForm.creativeStatusName) && submissionForm.creativeNextStageName === submissionForm.currentStageName
                ) {
                    if (submissionForm.submissionTypeName === STANDARD_SUBMISSION_TYPE && submissionForm.currentStageName !== ANNUALS_STAGE) {
                        errorCreativeNextStage.value = 'Please select a different value for next stage other than the current stage.';
                        submissionFormValidations.creativeNextStage = REQUIRED_FIELD;
                    } else if (submissionForm.submissionTypeName === GENERIC_SUBMISSION_TYPE && (submissionForm.currentStageName !== ANNUALS_STAGE || submissionForm.currentStageName !== FINAL_STAGE)) {
                        errorCreativeNextStage.value = 'Please select a different value for next stage other than the current stage.';
                        submissionFormValidations.creativeNextStage = REQUIRED_FIELD;
                    } else if (submissionForm.submissionTypeName === INTERACTIVE_SUBMISSION_TYPE && submissionForm.currentStageName !== FINAL_STAGE) {
                        errorCreativeNextStage.value = 'Please select a different value for next stage other than the current stage.';
                        submissionFormValidations.creativeNextStage = REQUIRED_FIELD;
                    }
                }
            },
            {
                deep: true,
                immediate: true
            }
        );

        const currentStageFieldValues = () => {
            let _stagesToShow;
            // Current Stage is Production or above
            if ([PRODUCTION_STAGE, CONTRACTUAL_STAGE, ANNUALS_STAGE, FINAL_STAGE, REVISED_STAGE].includes(submissionForm.currentStageName)) {
                _stagesToShow = __submissionStages.value.filter(s => [PRODUCTION_STAGE, CONTRACTUAL_STAGE, ANNUALS_STAGE, FINAL_STAGE, REVISED_STAGE].includes(s.stageName));
            } else {
                // Current Stage is before Production
                _stagesToShow = __submissionStages.value.filter(s => ![PRODUCTION_STAGE, CONTRACTUAL_STAGE, ANNUALS_STAGE, FINAL_STAGE, REVISED_STAGE].includes(s.stageName));
            }
            return getDistinctArray(_stagesToShow);
        };

        watch(
            () => submissionForm.currentStage,
            () => {
                if (!submissionForm.currentStage && currentStageFieldState.value == EDITABLE) submissionFormValidations.currentStage = REQUIRED_FIELD
                else submissionFormValidations.currentStage = '';
            }
        );

        return {
            INVISIBLE,
            REQUIRED,
            VIEW_ONLY,
            submissionForm,
            // submission routing validations
            submissionFormValidations,
            rushSubmissionFieldState,
            safetyNeededFieldState,
            escalateToSupervisorFieldState,
            placeSampleHoldFieldState,
            placeSafetyHoldFieldState,
            safetyCompletedFieldState,
            currentStageFieldState,
            previousFieldsState,
            nextStageFieldState,
            legalNextStageFieldState,
            currentStepFieldState,
            nextStepFieldState,
            legalStatusFieldState,
            creativeStatusFieldState,
            creativeNextStageFieldState,
            submissionStatusFieldState,
            submissionRoutingSectionState,
            sendToFinalFieldState,
            skipToFinalFieldState,
            currentActionFieldState,
            // submission routing dropdowns
            currentStageFieldValues,
            __posssibleSubmissionStages,
            __submissionSteps,
            __submissionStatusList,
            submissionReviewers,
            submissionReviewerField,
            submissionAssignToUserList,
            listNameLegalReview,
            listNameCreativeReview,
            listNameCurrentAction,
            submissionStatusListLegal,
            submissionStatusListCreative,
            submissionStatusListAction,
            getDistinctArray,
            fetchedUserListOnBaseOfRole,
            submissionUserFieldState,
            showSafetyNeededModal,
            setSafetyNeededModalVisibility,
            confirmedSafetyNeededCanBeNo,
            BAC_SUPERVISOR_STEP,
            currentActionFieldErrorState,
            errorTextActionFieldBAC,
            errorLegalNextStage,
            errorCreativeNextStage,
            filterNextStagesForLegalAndCreative,
            showIsSafetyCompletedConfirmationModal,
            confirmIsSafetyCompletedConfirmationModal,
            closeIsSafetyCompletedConfirmationModal,
            resetSafetyNeededToYes,
            hideIsSafetyCompletedConfirmationModal,

            isLoading
        };
    }
};
</script>
